import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "@components/Select";
import Input from "@components/Input";
import { Grid, Row, Col } from "@components/Grid";
import FormWrapper from "@components/FormWrapper";
import Modal from "@components/Modal";
import Loading from "@components/Loading";
import { getErrors, displayErrorMessage } from "@utils";
import CheckboxGroup from "@components/CheckboxGroup";
import Label from "@wfp/ui/src/components/FormLabel/RfFormLabel";

import Tag from "../../../../components/Tag";


// The AdministrationUserEdit component is used to edit user roles
const AdministrationUserEditModal = ({
  errorObject,
  userRoles,
  countries,
  divisions,
  org_unit_description,
  isOpen,
  onValidate,
  onCancel,
  errorMessage,
  isLoading,
  isOnEdit
}) => (
  <Modal isOpen={isOpen} title="Edit user" onValidate={onValidate} onCancel={onCancel} validateText="Save">
    {displayErrorMessage(errorMessage)}
    <FormWrapper>
      <Grid>
        <Row>
          <Col md={6}>
            <Field
              component={Input}
              name="first_name"
              id="first_name"
              label="First Name"
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "first_name")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Input}
              name="last_name"
              id="last_name"
              label="Last Name"
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "last_name")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={Input}
              name="username"
              id="username"
              label="Username/Email Address"
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "username")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Select}
              name="country"
              id="country"
              label="Country"
              options={countries}
              smallMenu
              errorMessage={getErrors(errorObject, "country")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label>Division</Label> <br/>
            {org_unit_description?
              org_unit_description.split(",").map((item, idx) => <Tag key={idx}>{item}</Tag>) :
              "–"}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <CheckboxGroup name="roles" title="User Roles" options={userRoles} />
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the Modal component
AdministrationUserEditModal.propTypes = {
  errorObject: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOnEdit: PropTypes.bool.isRequired,
  userRoles: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  org_unit_description: PropTypes.string
};

export default reduxForm({ form: "adminUserEditForm", enableReinitialize: true })(AdministrationUserEditModal);
