import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";

import Module, { SectionTitle } from "../../../../components/Module";
import Select from "../../../../components/Select";
import Checkbox from "../../../../components/Checkbox";
import Input from "../../../../components/Input";
import { Grid, Row, Col } from "../../../../components/Grid";
import FormWrapper from "../../../../components/FormWrapper";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import ShowHideArea from "../../../../components/ShowHideArea";
import Button from "../../../../components/Button";
import Spacer from "../../../../components/Spacer";
import { displayErrorMessage, getErrors, displayWarningMessage } from "../../../../utils";
import { normalizeNumber } from "../../../../utils/reduxForm";

// A function used to display a message when no options are found
export const noOptionsMessageGrants = input => {
  const filter = input.inputValue.replace(/^[0]+/g, "");
  return filter.length > 3 ? "This grant number doesn't exist" : "Start typing...";
};

const SHFOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

// The CriteriaGcmf component allows user to select a specific commodity from a list
const CriteriaGcmf = ({
  isVisible,
  specificCommodities,
  gcmfZones,
  gcmfSubzones,
  errorMessage,
  onClickNext,
  errorObject,
  loadingGcmfZones,
  loadingGcmfSubones,
  loadingSpecificCommodities,
  warningMessageCommodityPrice,
  onSelectCommodityPrice,
  onSelectGcmfZone,
  isOnEdit,
  onClickDraft
}) => (
  <ShowHideArea isVisible={isVisible}>
    <Module
      title="Step 1/7 Criteria"
      footer={
        <ButtonsWrapper>
          <Button kind="secondary" onClick={onClickDraft}>Save Draft</Button>
          <Button onClick={onClickNext}>Continue</Button>
        </ButtonsWrapper>
      }
    >
      {displayErrorMessage(errorMessage)}
      {displayWarningMessage(warningMessageCommodityPrice)}
      <FormWrapper>
        <SectionTitle title="Commodity" />
        <Grid>
          <Row>
            <Col md={6}>
              <Field
                component={Select}
                name="commodity"
                id="commodity"
                label="Commodity"
                options={specificCommodities}
                loading={loadingSpecificCommodities}
                onChange={onSelectCommodityPrice}
                isDisabled={isOnEdit}
                errorMessage={getErrors(errorObject, "commodity")}
              />
            </Col>
            <Col md={4}>
              <Field
                component={Input}
                name="quantity"
                id="quantity"
                label="Quantity (Mt)"
                normalize={(value) => normalizeNumber(value,3)}
                errorMessage={getErrors(errorObject, "quantity")}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                component={Select}
                name="small_holder_farmer_gcmf"
                id="small_holder_farmer_gcmf"
                label="Smallholder farmer"
                options={SHFOptions}
                errorMessage={getErrors(errorObject, "small_holder_farmer_gcmf")}
              />
              <Field
                component={Checkbox}
                name="gmo_commodity_gcmf"
                id="gmo_commodity_gcmf"
                text="GMO"
                single
                errorMessage={getErrors(errorObject, "gmo_commodity_gcmf")}
              />
            </Col>
          </Row>
        </Grid>
        <SectionTitle title="Project details" withSeparator />
        <Grid>
          <Row>
            <Col md={6}>
              <Field
                component={Select}
                name="gcmf_zone"
                id="gcmf_zone"
                label="GCMF supply line"
                options={gcmfZones}
                loading={loadingGcmfZones}
                onChange={onSelectGcmfZone}
                isDisabled={isOnEdit || loadingGcmfSubones}
                errorMessage={getErrors(errorObject, "gcmf_zone")}
              />
            </Col>
            <Col md={6}>
              <Field
                component={Select}
                name="gcmf_subzone"
                id="gcmf_subzone"
                label="GCMF corridor"
                options={gcmfSubzones}
                loading={loadingGcmfSubones}
                isDisabled={loadingGcmfSubones}
                errorMessage={getErrors(errorObject, "gcmf_subzone")}
              />
            </Col>
          </Row>
        </Grid>
        <Spacer height="8rem" />
      </FormWrapper>
    </Module>
  </ShowHideArea>
);

// propTypes for the CriteriaGcmf component
CriteriaGcmf.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  gcmfZones: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  gcmfSubzones: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  errorMessage: PropTypes.string.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickDraft: PropTypes.func.isRequired,
  errorObject: PropTypes.object.isRequired,
  warningMessageCommodityPrice: PropTypes.string.isRequired,
  loadingSpecificCommodities: PropTypes.bool.isRequired,
  loadingGcmfZones: PropTypes.bool.isRequired,
  loadingGcmfSubones: PropTypes.bool.isRequired,
  onSelectGcmfZone: PropTypes.func.isRequired,
  onSelectCommodityPrice: PropTypes.func.isRequired,
  isOnEdit: PropTypes.bool.isRequired
};

export default reduxForm({ form: "ipfDetailCriteriaGcmf", enableReinitialize: true })(CriteriaGcmf);
