import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reset, change } from "redux-form";
import { iconAddGlyph } from "@wfp/icons";

import PageStandard from "../../components/PageStandard";
import { adaptOptions, adaptOption, isEmpty, openUrl } from "../../utils";
import { adaptCommodityPrices } from "../../utils/commodityPrice";
import { clearNumber, getStatusErrorMessage } from "../../utils";
import CommodityPriceList from "./components/CommodityPriceList";
import CommodityPriceModal from "./components/CommodityPriceModal";
import RequestPriceModal from "./components/RequestPriceModal";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import ModalSuccess from "../../components/ModalSuccess";
import ModalConfirm from "../../components/ModalConfirm";
import { hasPermission } from "../../utils/permissions";
import {
  commodityPriceList,
  commodityPriceCurrentPage,
  specificCommodityList,
  countryList,
  incotermList,
  showCommodityPriceModal,
  hideCommodityPriceModal,
  commodityPriceAdd,
  commodityPriceAddReset,
  locationList,
  commodityPriceDetail,
  commodityPriceDetailReset,
  commodityPriceUpdate,
  commodityPriceUpdateReset,
  showRequestPriceModal,
  hideRequestPriceModal,
  requestPriceAdd,
  showSuccessRequestPriceModal,
  hideSuccessRequestPriceModal,
  requestPriceAddReset,
  commodityPriceDelete,
  showDeleteCommodityPriceModal,
  hideDeleteCommodityPriceModal,
  activeRecordCommodityPrice,
  userSearchDefaultDetail,
  userSearchDefaultAdd,
  userSearchDefaultDetailReset,
  commodityPriceConfirm,
  showConfirmCommodityPriceModal,
  hideConfirmCommodityPriceModal,
  userDetail,
  locationListReset,
  packagingTypeList
} from "@actions";
import { PRICE_TYPES } from "../../constants";

const API_URL_DOWNLOAD_XLS = `${process.env.API_BASE_URL}/api/commodity_prices/xls/`;

// A function to adapt form values to be compliant with the back-end expected format
export const adaptCommodityPriceFormValues = values =>
  values && {
    ...values,
    specific_commodity: values.specific_commodity && values.specific_commodity.value,
    origin_country: (values.origin_country && values.origin_country.value) || undefined,
    incoterm: (values.incoterm && values.incoterm.value) || undefined,
    incoterm_place: (values.incoterm_place && values.incoterm_place.value) || undefined,
    packaging_type: values.packaging_type && values.packaging_type.value,
    price_type: values.price_type && values.price_type.value,
    price: (values.price && clearNumber(values.price)) || undefined
  };

// A function to adapt form values to be compliant with the front-end expected format
export const adaptCommodityPriceInitialFormValues = values =>
  values && {
    ...values,
    specific_commodity:
      (values.specific_commodity && {
        value: values.specific_commodity.code,
        label: values.specific_commodity.description
      }) ||
      undefined,
    origin_country:
      (values.origin_country && { value: values.origin_country.iso_code, label: values.origin_country.name }) ||
      undefined,
    incoterm:
      (values.incoterm && {
        value: values.incoterm.code,
        label: `${values.incoterm.description} (${values.incoterm.code})`
      }) ||
      undefined,
    incoterm_place:
      (values.incoterm_place && { value: values.incoterm_place.id, label: values.incoterm_place.name }) || undefined,
    packaging_type: (values.packaging_type && adaptOption(values.packaging_type, "code", "description")) || undefined,
    price_type:
      (values.price_type &&
        adaptOption(PRICE_TYPES.find(priceType => values.price_type === priceType.value), "value", "description")) ||
      undefined,
    price: values.price || undefined
  };

// A function to adapt form values to be compliant with the back-end expected format
export const adaptRequestPriceFormValues = values =>
  values && {
    ...values,
    commodity: values.commodity && values.commodity.value,
    origin_country: (values.origin_country && values.origin_country.value) || undefined,
    incoterm: (values.incoterm && values.incoterm.value) || undefined,
    incoterm_place: (values.incoterm_place && values.incoterm_place.value) || undefined,
    packaging_type: (values.packaging_type && values.packaging_type.value) || undefined,
    price_type: (values.price_type && values.price_type.value) || undefined
  };

// A page to show the list of Commodity Prices
export class CommodityPrices extends Component {
  // Binding events to this
  constructor(props) {
    super(props);
    this.onPageChangeCommodityPrices = this.onPageChangeCommodityPrices.bind(this);
    this.onClickSearchCommodityPrices = this.onClickSearchCommodityPrices.bind(this);
    this.onValidateCommodityPriceModal = this.onValidateCommodityPriceModal.bind(this);
    this.onCancelCommodityPriceModal = this.onCancelCommodityPriceModal.bind(this);
    this.onClickCreateCommodityPrice = this.onClickCreateCommodityPrice.bind(this);
    this.onClickEditCommodityPrice = this.onClickEditCommodityPrice.bind(this);
    this.onClickRequestPrice = this.onClickRequestPrice.bind(this);
    this.onCancelRequestPriceModal = this.onCancelRequestPriceModal.bind(this);
    this.onValidateRequestPriceModal = this.onValidateRequestPriceModal.bind(this);
    this.onValidateSuccessModal = this.onValidateSuccessModal.bind(this);
    this.onValidateCommodityPriceDeleteModal = this.onValidateCommodityPriceDeleteModal.bind(this);
    this.onValidateConfirmPriceModal = this.onValidateConfirmPriceModal.bind(this);
    this.onCancelCommodityPriceDeleteModal = this.onCancelCommodityPriceDeleteModal.bind(this);
    this.onCancelConfirmPriceModal = this.onCancelConfirmPriceModal.bind(this);
    this.onClickDeleteCommodityPrice = this.onClickDeleteCommodityPrice.bind(this);
    this.onClickResetFiltersForm = this.onClickResetFiltersForm.bind(this);
    this.onClickResetPreset = this.onClickResetPreset.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickExport = this.onClickExport.bind(this);
    this.onChangeCommodityPriceProjectCountry = this.onChangeCommodityPriceProjectCountry.bind(this);
    this.onChangeRequestPriceProjectCountry = this.onChangeRequestPriceProjectCountry.bind(this);
  }

  // On component mount, data are loaded
  componentDidMount() {
    this.props.specificCommodityList();
    this.props.incotermList();
    this.props.countryList();
    this.props.packagingTypeList();
    this.props.userDetail();
    this.props.userSearchDefaultDetail().finally(() => this.filterInitial());
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    this.props.userSearchDefaultDetailReset();
    this.props.commodityPriceAddReset();
    this.props.commodityPriceUpdateReset();
    this.props.commodityPriceDetailReset();
    this.props.commodityPriceCurrentPage(0);
    this.props.activeRecordCommodityPrice(0);
    this.resetCommodityPriceModal();
    this.resetRequestPriceModal();
    this.resetCommodityPriceDeleteModal();
    this.resetConfirmPriceModal();
  }

  // A method to filter by props and and given filter
  filterCommodityPrices(page) {
    const { selectedCountries, selectedCommodities, selectedIncoterms } = this.props;
    this.props.commodityPriceCurrentPage(page);
    this.props.commodityPriceList(page + 1, selectedCountries, selectedCommodities, selectedIncoterms);
  }

  // A method to filter by props and and given filter
  filterInitial() {
    const { presetCountries, presetCommodities, presetIncoterms } = this.props;
    this.props.commodityPriceCurrentPage(0);
    this.props.commodityPriceList(1, presetCountries, presetCommodities, presetIncoterms);
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeCommodityPrices(page) {
    this.filterCommodityPrices(page);
  }

  // When user click search, Commodity Prices will be filtered and the preset is saved, if user checked
  // the checkbox is set to false after the reset
  onClickSearchCommodityPrices() {
    this.filterCommodityPrices(0);
    if (this.props.shouldSavePreset) {
      this.props.clearSavePreset();
      this.props.userSearchDefaultAdd(this.props.filterFormValues);
    }
  }

  // When user click reset preset, filters are reset backend side,
  // the checkbox is set to false after the reset,
  // defaults should be deleted from redux store to clear form initial values
  // and, after that, the form should be reset to initial values
  onClickResetPreset() {
    this.props.userSearchDefaultAdd({});
    this.props.userSearchDefaultDetailReset();
    this.props.resetFiltersForm();
  }

  // Shows the modal to add a Commodity Price
  onClickCreateCommodityPrice() {
    this.props.showCommodityPriceModal();
  }

  // Shows the modal to request a Commodity Price
  onClickRequestPrice() {
    this.props.showRequestPriceModal();
  }

  // Shows the modal to edit a Commodity Price
  onClickEditCommodityPrice(id) {
    this.props.commodityPriceDetail(id).then(success => {
      if (success) {
        this.props.showCommodityPriceModal();
      }
    });
  }

  // Shows the modal to delete a Commodity Price
  onClickDeleteCommodityPrice(id) {
    this.props.activeRecordCommodityPrice(id);
    this.props.showDeleteCommodityPriceModal();
  }

  // Shows the modal to confirm a Commodity Price
  onClickConfirm(id) {
    this.props.activeRecordCommodityPrice(id);
    this.props.showConfirmCommodityPriceModal();
  }

  // Close the modal to add/edit a Commodity Price
  onCancelCommodityPriceModal() {
    this.resetCommodityPriceModal();
  }

  // Close the modal to add a Request Price
  onCancelRequestPriceModal() {
    this.resetRequestPriceModal();
  }

  // On click cancel on delete modal
  onCancelCommodityPriceDeleteModal() {
    this.resetCommodityPriceDeleteModal();
  }

  // On click cancel on confirm Price modal
  onCancelConfirmPriceModal() {
    this.resetConfirmPriceModal();
  }

  // Close the success modal
  onValidateSuccessModal() {
    this.props.hideSuccessModal();
  }

  // Update or add a commodity price
  onValidateCommodityPriceModal() {
    if (this.props.isOnEdit) {
      this.props
        .commodityPriceUpdate(
          this.props.currentIdCommodityPrice,
          adaptCommodityPriceFormValues(this.props.commodityPriceFormValues)
        )
        .then(success => {
          if (success) {
            this.resetCommodityPriceModal();
            this.filterCommodityPrices(0);
          }
        });
    } else {
      this.props.commodityPriceAdd(
          adaptCommodityPriceFormValues(this.props.commodityPriceFormValues)
        )
        .then(success => {
          if (success) {
            this.resetCommodityPriceModal();
            this.filterCommodityPrices(0);
          }
      });
    }
  }

  // Adds a Commodity Price Request
  onValidateRequestPriceModal() {
    this.props.requestPriceAdd(adaptRequestPriceFormValues(this.props.requestPriceFormValues)).then(success => {
      if (success) {
        this.resetRequestPriceModal();
        this.props.showSuccessModal();
      }
    });
  }

  // Reset the modal to add a Commodity Price
  resetCommodityPriceModal() {
    this.props.hideCommodityPriceModal();
    this.props.resetCommodityPriceForm();
    this.props.commodityPriceAddReset();
    this.props.commodityPriceDetailReset();
    this.props.commodityPriceUpdateReset();
    this.props.locationListReset();
  }

  // Reset the modal to add a Request Commodity Price
  resetRequestPriceModal() {
    this.props.hideRequestPriceModal();
    this.props.resetRequestPriceForm();
    this.props.requestPriceAddReset();
    this.props.locationListReset();
  }

  // Reset the modal to delete a Commodity Price
  resetCommodityPriceDeleteModal() {
    this.props.hideDeleteCommodityPriceModal();
    this.props.activeRecordCommodityPrice(0);
  }

  // Reset the modal to confirm a Commodity Price
  resetConfirmPriceModal() {
    this.props.hideConfirmCommodityPriceModal();
    this.props.activeRecordCommodityPrice(0);
  }

  // On click validate on delete Commodity Price modal
  onValidateCommodityPriceDeleteModal() {
    this.props.commodityPriceDelete(this.props.idActiveRecordCommodityPrice).then(success => {
      if (success) {
        this.resetCommodityPriceDeleteModal();
        this.filterCommodityPrices(0);
      }
    });
  }

  // Confirm a Commodity Price, if confirmations goes right, the table is refreshed to show updated data
  onValidateConfirmPriceModal() {
    this.props.commodityPriceConfirm({}, `${this.props.idActiveRecordCommodityPrice}/confirm/`).then(success => {
      if (success) {
        this.resetConfirmPriceModal();
        this.filterCommodityPrices(0);
      }
    });
  }

  // On click reset defaults should be deleted from redux store to clear form initial values
  // and, after that, the form should be reset to initial values
  onClickResetFiltersForm() {
    this.props.userSearchDefaultDetailReset();
    this.props.resetFiltersForm();
  }

  // Data are exported as Excel file based on filters selected by user
  onClickExport() {
    let parameters = [];
    parameters.push({ label: "country", value: this.props.selectedCountries });
    parameters.push({ label: "commodity", value: this.props.selectedCommodities });
    parameters.push({ label: "incoterm", value: this.props.selectedIncoterms });
    openUrl(API_URL_DOWNLOAD_XLS, parameters);
  }

  // On select project country, incoterm places have to be filtered by country
  onChangeCommodityPriceProjectCountry(data) {
    this.props.locationListReset();
    this.props.clearCommodityPriceIncotermPlace();
    if (data && data.value) {
      this.props.locationList(data.value);
    }
  }

  // On select project country, incoterm places have to be filtered by country
  onChangeRequestPriceProjectCountry(data) {
    this.props.locationListReset();
    this.props.clearRequestPriceIncotermPlace();
    if (data && data.value) {
      this.props.locationList(data.value);
    }
  }

  render() {
    const {
      isFetchingCommodityPrices,
      isFetchingRelatedEntities,
      commodityPrices,
      errorMessageCommodityPrices,
      errorMessageFilters,
      totalItemsCommodityPrices,
      pageCommodityPrices,
      specificCommodities,
      countries,
      incoterms,
      isCommodityPriceModalOpen,
      errorObjectCommodityPriceModal,
      isLoadingCommodityPriceModal,
      initialFormValuesCommodityPrice,
      isOnEdit,
      isRequestPriceModalOpen,
      errorObjectRequestPriceModal,
      isLoadingRequestPriceModal,
      isSuccessModalOpen,
      isDeleteCommodityPriceModalOpen,
      errorMessageDeleteCommodityPrice,
      isLoadingDeleteCommodityPriceModal,
      initialFilters,
      isLoadingPresets,
      errorMessageConfirmCommodityPrice,
      isLoadingConfirmCommodityPrice,
      isConfirmCommodityPriceModalOpen,
      packagingTypes,
      priceTypes,
      requestPriceModalTitle,
      canAddCommodityPrice,
      canAddCommodityPriceRequest,
      canChangeCommodityPrice,
      canDeleteCommodityPrice,
      errorMessageRequestPriceModal,
      errorMessageCommodityPriceModal,
      locations
    } = this.props;
    
    return (
      <>
        <PageStandard
          title="Commodity Prices"
          additional={
            <ButtonsWrapper>
              {canAddCommodityPriceRequest && (
                <Button onClick={this.onClickRequestPrice} kind="secondary">
                  Request price
                </Button>
              )}
              {canAddCommodityPrice && (
                <Button onClick={this.onClickCreateCommodityPrice} icon={iconAddGlyph}>
                  Create price
                </Button>
              )}
            </ButtonsWrapper>
          }
        >
          <CommodityPriceList
            loadingCommodityPrices={isFetchingCommodityPrices}
            loadingFilters={isFetchingRelatedEntities}
            errorMessageCommodityPrices={errorMessageCommodityPrices}
            errorMessageFilters={errorMessageFilters}
            errorMessageConfirmCommodityPrice={errorMessageConfirmCommodityPrice}
            commodityPrices={commodityPrices}
            totalItems={totalItemsCommodityPrices}
            page={pageCommodityPrices}
            onPageChange={this.onPageChangeCommodityPrices}
            onClickSearch={this.onClickSearchCommodityPrices}
            onClickEdit={this.onClickEditCommodityPrice}
            onClickConfirm={this.onClickConfirm}
            onClickDelete={this.onClickDeleteCommodityPrice}
            specificCommodities={specificCommodities}
            countries={countries}
            incoterms={incoterms}
            onClickReset={this.onClickResetFiltersForm}
            onClickResetPreset={this.onClickResetPreset}
            initialValues={initialFilters}
            loadingPresets={isLoadingPresets}
            canChangeCommodityPrice={canChangeCommodityPrice}
            canDeleteCommodityPrice={canDeleteCommodityPrice}
            onClickExport={this.onClickExport}
          />
        </PageStandard>
        <CommodityPriceModal
          isOpen={isCommodityPriceModalOpen}
          onValidate={this.onValidateCommodityPriceModal}
          onCancel={this.onCancelCommodityPriceModal}
          errorObject={errorObjectCommodityPriceModal}
          loadingRelatedEntities={isFetchingRelatedEntities}
          specificCommodities={specificCommodities}
          countries={countries}
          incoterms={incoterms}
          packagingTypes={packagingTypes}
          isLoading={isLoadingCommodityPriceModal}
          locations={locations}
          initialValues={initialFormValuesCommodityPrice}
          isOnEdit={isOnEdit}
          priceTypes={priceTypes}
          errorMessage={errorMessageCommodityPriceModal}
          onChangeProjectCountry={this.onChangeCommodityPriceProjectCountry}
        />
        <RequestPriceModal
          isOpen={isRequestPriceModalOpen}
          onValidate={this.onValidateRequestPriceModal}
          onCancel={this.onCancelRequestPriceModal}
          errorObject={errorObjectRequestPriceModal}
          loadingRelatedEntities={isFetchingRelatedEntities}
          specificCommodities={specificCommodities}
          countries={countries}
          incoterms={incoterms}
          isLoading={isLoadingRequestPriceModal}
          locations={locations}
          packagingTypes={packagingTypes}
          priceTypes={priceTypes}
          title={requestPriceModalTitle}
          errorMessage={errorMessageRequestPriceModal}
          onChangeProjectCountry={this.onChangeRequestPriceProjectCountry}
        />
        <ModalSuccess
          isOpen={isSuccessModalOpen}
          onValidate={this.onValidateSuccessModal}
          text="Your request has been sent"
        />
        <ModalConfirm
          isOpen={isDeleteCommodityPriceModalOpen}
          errorMessage={errorMessageDeleteCommodityPrice}
          onValidate={this.onValidateCommodityPriceDeleteModal}
          onCancel={this.onCancelCommodityPriceDeleteModal}
          isLoading={isLoadingDeleteCommodityPriceModal}
          title="Delete Commodity Price"
          text="Are you sure you want to delete this Commodity Price?"
        />
        <ModalConfirm
          isOpen={isConfirmCommodityPriceModalOpen}
          errorMessage={errorMessageConfirmCommodityPrice}
          onValidate={this.onValidateConfirmPriceModal}
          onCancel={this.onCancelConfirmPriceModal}
          isLoading={isLoadingConfirmCommodityPrice}
          title="Confirm Commodity Price"
          text="Are you sure you want to confirm this Commodity Price?"
        />
      </>
    );
  }
}

// propTypes for the CommodityPrices component
CommodityPrices.propTypes = {
  isFetchingCommodityPrices: PropTypes.bool.isRequired,
  isFetchingRelatedEntities: PropTypes.bool.isRequired,
  commodityPrices: PropTypes.array.isRequired,
  commodityPriceList: PropTypes.func.isRequired,
  incotermList: PropTypes.func.isRequired,
  errorMessageCommodityPrices: PropTypes.string.isRequired,
  errorMessageFilters: PropTypes.string.isRequired,
  totalItemsCommodityPrices: PropTypes.number.isRequired,
  pageCommodityPrices: PropTypes.number.isRequired,
  commodityPriceCurrentPage: PropTypes.func.isRequired,
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  incoterms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  packagingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  priceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  specificCommodityList: PropTypes.func.isRequired,
  countryList: PropTypes.func.isRequired,
  selectedCommodities: PropTypes.array.isRequired,
  selectedCountries: PropTypes.array.isRequired,
  selectedIncoterms: PropTypes.array.isRequired,
  isCommodityPriceModalOpen: PropTypes.bool,
  showCommodityPriceModal: PropTypes.func.isRequired,
  hideCommodityPriceModal: PropTypes.func.isRequired,
  resetCommodityPriceForm: PropTypes.func.isRequired,
  commodityPriceAdd: PropTypes.func.isRequired,
  errorObjectCommodityPriceModal: PropTypes.object.isRequired,
  commodityPriceFormValues: PropTypes.any,
  commodityPriceAddReset: PropTypes.func.isRequired,
  isLoadingCommodityPriceModal: PropTypes.bool.isRequired,
  locationList: PropTypes.func.isRequired,
  commodityPriceDetail: PropTypes.func.isRequired,
  initialFormValuesCommodityPrice: PropTypes.object.isRequired,
  isOnEdit: PropTypes.bool.isRequired,
  commodityPriceDetailReset: PropTypes.func.isRequired,
  commodityPriceUpdateReset: PropTypes.func.isRequired,
  commodityPriceUpdate: PropTypes.func.isRequired,
  currentIdCommodityPrice: PropTypes.number,
  showRequestPriceModal: PropTypes.func.isRequired,
  isRequestPriceModalOpen: PropTypes.bool.isRequired,
  hideRequestPriceModal: PropTypes.func.isRequired,
  requestPriceAdd: PropTypes.func.isRequired,
  errorObjectRequestPriceModal: PropTypes.object.isRequired,
  requestPriceFormValues: PropTypes.any,
  isLoadingRequestPriceModal: PropTypes.bool.isRequired,
  isSuccessModalOpen: PropTypes.bool.isRequired,
  showSuccessModal: PropTypes.func.isRequired,
  hideSuccessModal: PropTypes.func.isRequired,
  resetRequestPriceForm: PropTypes.func.isRequired,
  requestPriceAddReset: PropTypes.func.isRequired,
  showDeleteCommodityPriceModal: PropTypes.func.isRequired,
  hideDeleteCommodityPriceModal: PropTypes.func.isRequired,
  isDeleteCommodityPriceModalOpen: PropTypes.bool.isRequired,
  activeRecordCommodityPrice: PropTypes.func.isRequired,
  idActiveRecordCommodityPrice: PropTypes.any.isRequired,
  commodityPriceDelete: PropTypes.func.isRequired,
  errorMessageDeleteCommodityPrice: PropTypes.string.isRequired,
  isLoadingDeleteCommodityPriceModal: PropTypes.bool.isRequired,
  resetFiltersForm: PropTypes.func.isRequired,
  shouldSavePreset: PropTypes.bool.isRequired,
  clearSavePreset: PropTypes.func.isRequired,
  userSearchDefaultDetail: PropTypes.func.isRequired,
  userSearchDefaultAdd: PropTypes.func.isRequired,
  presetCommodities: PropTypes.array.isRequired,
  presetCountries: PropTypes.array.isRequired,
  presetIncoterms: PropTypes.array.isRequired,
  filterFormValues: PropTypes.any.isRequired,
  initialFilters: PropTypes.any.isRequired,
  userSearchDefaultDetailReset: PropTypes.func.isRequired,
  isLoadingPresets: PropTypes.bool.isRequired,
  errorMessageConfirmCommodityPrice: PropTypes.string.isRequired,
  isLoadingConfirmCommodityPrice: PropTypes.bool.isRequired,
  commodityPriceConfirm: PropTypes.func.isRequired,
  showConfirmCommodityPriceModal: PropTypes.func.isRequired,
  hideConfirmCommodityPriceModal: PropTypes.func.isRequired,
  isConfirmCommodityPriceModalOpen: PropTypes.bool.isRequired,
  requestPriceModalTitle: PropTypes.string.isRequired,
  userDetail: PropTypes.func.isRequired,
  canAddCommodityPrice: PropTypes.bool.isRequired,
  canAddCommodityPriceRequest: PropTypes.bool.isRequired,
  canChangeCommodityPrice: PropTypes.bool.isRequired,
  canDeleteCommodityPrice: PropTypes.bool.isRequired,
  errorMessageRequestPriceModal: PropTypes.string.isRequired,
  errorMessageCommodityPriceModal: PropTypes.string.isRequired,
  locationListReset: PropTypes.func.isRequired,
  clearCommodityPriceIncotermPlace: PropTypes.func.isRequired,
  clearRequestPriceIncotermPlace: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  packagingTypeList: PropTypes.func.isRequired
};

// defaultProps for the CommodityPrices component
CommodityPrices.defaultProps = {
  isCommodityPriceModalOpen: false,
  commodityPriceFormValues: undefined,
  requestPriceFormValues: undefined,
  currentIdCommodityPrice: undefined
};

// Starting from the redux state it gets data related to Commodity Price list
export const mapStateToProps = state => {
  const {
    userDetail,
    form: { commodityPricesFilters },
    userSearchDefaultDetail
  } = state;

  // A function to check if user has given permission
  const checkUserPermission = permission => {
    return (userDetail && userDetail.data && hasPermission(userDetail.data, permission)) || false;
  };

  // A funcion to create an array of applied filters
  const createFiltersArray = (filters, property) => {
    const hasProperty = filters && filters.values && filters.values[property];
    let filterArray = [];
    if (hasProperty) filterArray = filters.values[property].map(element => element.value);
    return filterArray;
  };

  const createPreferencesArray = (filters, property) => {
    const hasProperty = filters && filters.data && filters.data.preferences && filters.data.preferences[property];
    let filterArray = [];
    if (hasProperty) filterArray = filters.data.preferences[property].map(element => element.value);
    return filterArray;
  };
  
  return {
    // Related entities properties
    // user is not able to select regional type from source type
    errorObjectCommodityPriceModal: state.commodityPriceAdd.error,
    specificCommodities: adaptOptions(state.specificCommodityList.data.results, "code", "description"),
    countries: adaptOptions(state.countryList.data.results, "iso_code", "name"),
    incoterms: adaptOptions(state.incotermList.data.results, "code", "description", "code"),
    packagingTypes: adaptOptions(state.packagingTypeList.data.results, "code", "description"),
    priceTypes: adaptOptions(PRICE_TYPES, "value", "description").filter(type => type.value !== "reg"),
    locations: adaptOptions(state.locationList.data.results, "id", "name"),
    isFetchingRelatedEntities:
      state.specificCommodityList.isFetching ||
      state.countryList.isFetching ||
      state.incotermList.isFetching ||
      state.locationList.isFetching ||
      state.packagingTypeList.isFetching,
    errorMessageFilters:
      state.specificCommodityList.errorMessage ||
      state.countryList.errorMessage ||
      state.incotermList.errorMessage ||
      state.locationList.errorMessage ||
      state.packagingTypeList.errorMessage,
    // Commodity Prices list properties
    commodityPrices: adaptCommodityPrices(state.commodityPriceList.data.results),
    isFetchingCommodityPrices: state.commodityPriceList.isFetching,
    totalItemsCommodityPrices: state.commodityPriceList.data.count || 0,
    errorMessageCommodityPrices: state.commodityPriceList.errorMessage,
    pageCommodityPrices: state.commodityPriceCurrentPage.number,
    // Add/edit Commodity Prices modal
    // in case of a duplicated commodity price, an error message is displayed
    isCommodityPriceModalOpen: state.commodityPriceModal.isVisible,
    commodityPriceFormValues: state.form.commodityPriceForm && state.form.commodityPriceForm.values,
    isLoadingCommodityPriceModal: state.commodityPriceAdd.isLoading || state.commodityPriceUpdate.isLoading, 
    initialFormValuesCommodityPrice: adaptCommodityPriceInitialFormValues(state.commodityPriceDetail.data),
    isOnEdit: !isEmpty(state.commodityPriceDetail.data),
    currentIdCommodityPrice: state.commodityPriceDetail.data.id,
    errorMessageCommodityPriceModal:
     (!isEmpty(state.commodityPriceAdd.error) &&
        !isEmpty(state.commodityPriceAdd.error.data) &&
        state.commodityPriceAdd.error.data.commodity_price &&
        state.commodityPriceAdd.error.data.commodity_price.length > 0 &&
        state.commodityPriceAdd.error.data.commodity_price[0]) ||
      "",
    // Request Commodity Price modal
    isRequestPriceModalOpen: state.requestPriceModal.isVisible,
    isLoadingRequestPriceModal: state.requestPriceAdd.isLoading,
    requestPriceFormValues: state.form.requestPriceForm && state.form.requestPriceForm.values,
    errorObjectRequestPriceModal: state.requestPriceAdd.error,
    isSuccessModalOpen: state.successRequestPriceModal.isVisible,
    requestPriceModalTitle:
      (state.form.requestPriceForm &&
        state.form.requestPriceForm.values &&
        state.form.requestPriceForm.values.price_type &&
        `New ${state.form.requestPriceForm.values.price_type.label} Price Request`) ||
      "New Price Request",
    errorMessageRequestPriceModal:
      (!isEmpty(state.requestPriceAdd.error) && getStatusErrorMessage(state.requestPriceAdd.error)) || "",
    // Delete Commodity Price modal
    isDeleteCommodityPriceModalOpen: state.deleteCommodityPriceModal.isVisible,
    isLoadingDeleteCommodityPriceModal: state.commodityPriceDelete.isLoading,
    errorMessageDeleteCommodityPrice: state.commodityPriceDelete.errorMessage,
    // Confirm Commodity Price action
    isConfirmCommodityPriceModalOpen: state.confirmCommodityPriceModal.isVisible,
    isLoadingConfirmCommodityPrice: state.commodityPriceConfirm.isLoading,
    errorMessageConfirmCommodityPrice: isEmpty(state.commodityPriceConfirm.error) ? "" : "Unable to confirm the price",
    // Current record to confirm or delete
    idActiveRecordCommodityPrice: state.activeRecordCommodityPrice.id,
    // Save preset options, filters form values and user default settings
    shouldSavePreset:
      (state.form &&
        state.form.commodityPricesFilters &&
        state.form.commodityPricesFilters.values &&
        state.form.commodityPricesFilters.values.savePreset) ||
      false,
    isLoadingPresets: state.userSearchDefaultDetail.isFetching || state.userSearchDefaultAdd.isLoading,
    initialFilters:
      (state.userSearchDefaultDetail &&
        state.userSearchDefaultDetail.data &&
        state.userSearchDefaultDetail.data.preferences) ||
      {},
    presetCountries: createPreferencesArray(userSearchDefaultDetail, "countries"),
    presetCommodities: createPreferencesArray(userSearchDefaultDetail, "commodities"),
    presetIncoterms: createPreferencesArray(userSearchDefaultDetail, "incoterms"),
    // Selected filters properties and initial filters form values for Commodity Prices
    filterFormValues:
      (state.form &&
        state.form.commodityPricesFilters && { ...state.form.commodityPricesFilters.values, savePreset: false }) ||
      {},
    selectedCountries: createFiltersArray(commodityPricesFilters, "countries"),
    selectedCommodities: createFiltersArray(commodityPricesFilters, "commodities"),
    selectedIncoterms: createFiltersArray(commodityPricesFilters, "incoterms"),
    // User permission management
    canAddCommodityPrice: checkUserPermission("prices.add_commodityprice"),
    canAddCommodityPriceRequest: checkUserPermission("prices.add_commoditypricerequest"),
    canChangeCommodityPrice: checkUserPermission("prices.change_commodityprice"),
    canDeleteCommodityPrice: checkUserPermission("prices.delete_commodityprice")
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    commodityPriceList: (page, country, commodity, incoterm) =>
      dispatch(commodityPriceList({ page, country, commodity, incoterm })),
    locationList: country__iso_code => dispatch(locationList({ country__iso_code })),
    commodityPriceAdd: data => dispatch(commodityPriceAdd(data)),
    commodityPriceConfirm: (data, additionalUrl) => dispatch(commodityPriceConfirm(data, additionalUrl)),
    requestPriceAdd: data => dispatch(requestPriceAdd(data)),
    requestPriceAddReset: () => dispatch(requestPriceAddReset()),
    commodityPriceUpdate: (id, data) => dispatch(commodityPriceUpdate(id, data)),
    commodityPriceCurrentPage: number => dispatch(commodityPriceCurrentPage(number)),
    specificCommodityList: () => dispatch(specificCommodityList()),
    countryList: () => dispatch(countryList()),
    incotermList: () => dispatch(incotermList()),
    showCommodityPriceModal: () => dispatch(showCommodityPriceModal()),
    hideCommodityPriceModal: () => dispatch(hideCommodityPriceModal()),
    resetCommodityPriceForm: () => dispatch(reset("commodityPriceForm")),
    commodityPriceAddReset: () => dispatch(commodityPriceAddReset()),
    commodityPriceDetail: id => dispatch(commodityPriceDetail(id)),
    commodityPriceDetailReset: () => dispatch(commodityPriceDetailReset()),
    commodityPriceUpdateReset: () => dispatch(commodityPriceUpdateReset()),
    showRequestPriceModal: () => dispatch(showRequestPriceModal()),
    hideRequestPriceModal: () => dispatch(hideRequestPriceModal()),
    showSuccessModal: () => dispatch(showSuccessRequestPriceModal()),
    hideSuccessModal: () => dispatch(hideSuccessRequestPriceModal()),
    resetRequestPriceForm: () => dispatch(reset("requestPriceForm")),
    resetFiltersForm: () => dispatch(reset("commodityPricesFilters")),
    commodityPriceDelete: id => dispatch(commodityPriceDelete(id)),
    showDeleteCommodityPriceModal: () => dispatch(showDeleteCommodityPriceModal()),
    hideDeleteCommodityPriceModal: () => dispatch(hideDeleteCommodityPriceModal()),
    showConfirmCommodityPriceModal: () => dispatch(showConfirmCommodityPriceModal()),
    hideConfirmCommodityPriceModal: () => dispatch(hideConfirmCommodityPriceModal()),
    activeRecordCommodityPrice: id => dispatch(activeRecordCommodityPrice(id)),
    userSearchDefaultDetail: () => dispatch(userSearchDefaultDetail("commodity_price")),
    userSearchDefaultAdd: data => dispatch(userSearchDefaultAdd({ search_type: "commodity_price", preferences: data })),
    clearSavePreset: () => dispatch(change("commodityPricesFilters", "savePreset", false)),
    clearCommodityPriceIncotermPlace: () => dispatch(change("commodityPriceForm", "incoterm_place", "")),
    clearRequestPriceIncotermPlace: () => dispatch(change("requestPriceForm", "incoterm_place", "")),
    userSearchDefaultDetailReset: () => dispatch(userSearchDefaultDetailReset()),
    locationListReset: () => dispatch(locationListReset()),
    userDetail: () => dispatch(userDetail()),
    packagingTypeList: () => dispatch(packagingTypeList())
  };
};

// The form is added to the redux store with a given name
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommodityPrices);
